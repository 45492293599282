const customStyles = {
    table: {
        style: {
            borderRadius: '15px'
        },
    },
    tableWrapper: {
        style: {
            display: 'table',
        },
    },
    responsiveWrapper: {
        style: {},
    },
    header: {
        style: {
            fontSize: '22px',
            minHeight: '56px',
            paddingLeft: '16px',
            paddingRight: '8px',
            borderRadius: '10px 10px 0 0'
        },
    },
    subHeader: {
        style: {
            minHeight: '52px',
        },
    },
    head: {
        style: {
            fontSize: '12px',
            fontWeight: 500,
        },
    },
    headRow: {
        style: {
            minHeight: '52px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
        },
        denseStyle: {
            minHeight: '32px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '16px',
            paddingRight: '16px',
            // width: '200px'
        },
        draggingStyle: {
            cursor: 'move',
        },
    },
    contextMenu: {
        style: {
            fontSize: '18px',
            fontWeight: 400,
            paddingLeft: '16px',
            paddingRight: '8px',
            transform: 'translate3d(0, -100%, 0)',
            transitionDuration: '125ms',
            transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
            willChange: 'transform',
        },
        activeStyle: {
            transform: 'translate3d(0, 0, 0)',
        },
    },
    cells: {
        style: {
            paddingLeft: '16px',
            paddingRight: '16px',
            wordBreak: 'break-word',
            width: '250px',

        },
        draggingStyle: {},
    },
    rows: {
        style: {
            fontSize: '13px',
            fontWeight: 400,
            minHeight: '48px',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
            },
        },
        denseStyle: {
            minHeight: '32px',
        },
        selectedHighlightStyle: {
            // use nth-of-type(n) to override other nth selectors
            '&:nth-of-type(n)': {
            },
        },
        highlightOnHoverStyle: {
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            outlineStyle: 'solid',
            outlineWidth: '1px',
        },
        stripedStyle: {
        },
    },
    expanderRow: {
        style: {
        },
    },
    expanderCell: {
        style: {
            flex: '0 0 48px',
        },
    },
    expanderButton: {
        style: {
            backgroundColor: 'transparent',
            borderRadius: '2px',
            transition: '0.25s',
            height: '100%',
            width: '100%',
            '&:hover:enabled': {
                cursor: 'pointer',
            },
            '&:disabled': {
            },
            '&:hover:not(:disabled)': {
                cursor: 'pointer',
            },
            '&:focus': {
                outline: 'none',
            },
            svg: {
                margin: 'auto',
            },
        },
    },
    pagination: {
        style: {
            fontSize: '13px',
            minHeight: '56px',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: 'px',
            cursor: 'pointer',
            transition: '0.4s',
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
            },
            '&:hover:not(:disabled)': {
            },
            '&:focus': {
                outline: 'none',
            },
        },
    },
    noData: {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    progress: {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
}

const smallerScreenMediaQuery = '@media screen and (max-width: 768px)';

customStyles.cells.style[smallerScreenMediaQuery] = {
    width: '150px' // Adjust this value as needed for smaller screens
};


export { customStyles };
import { useEffect, useState } from 'react';
import { Button, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ToastProvider, useDisclosure } from '@chakra-ui/react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import CustomLoader from 'components/customLoader';
import { FaFileCsv } from 'react-icons/fa';
import { FcEmptyTrash } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { MdError } from 'react-icons/md';

const RowModal = ({ isOpen, onClose, selectedRow, columns, onUpdate, onSave, saveLoad, errors, showJump, onDelete, columnType }) => {
    const [isEditingRow, setIsEditingRow] = useState(false);
    const [updatedRow, setUpdatedRow] = useState();
    const [imgBase, setImgBase] = useState()
    const [formatExport, setFormatExport] = useState()
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        if (selectedRow) {
            setUpdatedRow(selectedRow);
        }
    }, [selectedRow]);

    useEffect(() => {
        isEditingRow === true && setImgBase(!imgBase)
    }, [isEditingRow])

    const handleInputChange = async (event, id, accessor) => {
        const { value, type } = event.target;

        if (type === 'file') {
            let file = event.target.files[0]
            let imageData = new FileReader()

            imageData.onload = () => {
                const base64String = imageData.result.split(',')[1]

                setUpdatedRow(prevState => ({
                    ...prevState,
                    [accessor]: base64String
                }))

                onUpdate(base64String, id, accessor)
            }

            imageData.readAsDataURL(file)

        } else {
            setUpdatedRow(prevRow => ({
                ...prevRow,
                [accessor]: value
            }));

            onUpdate(value, id, accessor)
        }
    };

    const toggleEdit = () => setIsEditingRow(!isEditingRow); // Toggle edit mode

    const handleExport = () => {
        setFormatExport(true)
        const csvRows = [];

        const includedColumns = Object.keys(updatedRow);
        // Build the header row
        csvRows.push(includedColumns);

        const includedValues = includedColumns.map((columnName) => updatedRow[columnName]);
        csvRows.push(includedValues);

        setCsvData(csvRows);
        setFormatExport(false)
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size="full">
            <ModalContent>
                <ModalHeader>
                    <div className='sm:text-[28px] font-bold'>Full Data</div>
                    <CSVLink
                        data={csvData}
                        onClick={handleExport}
                        filename="mydata.csv"
                        className='linear flex items-center justify-center rounded bg-brand-300 p-2 text-base w-fit font-medium text-white transition duration-200 hover:bg-brand-400 active:bg-brand-500'
                    >
                        <FaFileCsv />
                    </CSVLink>
                    <ModalCloseButton className='mt-3 mr-2' fontSize={14} />
                </ModalHeader>

                <ModalBody>
                    {updatedRow &&
                        <>
                            <p className='text-lg'>ID: <span className='text-brand-400 font-bold'>
                                {selectedRow?.id}
                            </span></p>
                            {/* INPUTS RENDERING  */}
                            {columns?.map((column, index) => (
                                column?.name !== "ID" && column?.name !== "Trips" ? (
                                    <div className='flex flex-col gap-2 flex-wrap mb-4 column-name sm:flex-row sm:items-center' key={column?.accessor || column?.name || index}>
                                        <label className='font-bold text-navy-700'>{column?.name}:</label>

                                        {updatedRow && isEditingRow ?
                                            column.fileInput ? (
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id={selectedRow?.id}
                                                    name={column?.accessor}
                                                    onChange={(item) => handleInputChange(item, selectedRow?.id, column?.accessor)}
                                                />
                                            ) : (
                                                <div className='text-navy-900  cell-inputs-edit flex-grow'>
                                                    {column?.cell(updatedRow, handleInputChange)}
                                                </div>
                                            )
                                            :
                                            <div className='text-navy-900  cell-inputs flex-grow'>
                                                {column?.cell(updatedRow)}
                                            </div>
                                        }

                                    </div>
                                ) : null
                            ))}
                        </>
                    }

                    {!updatedRow && (<CustomLoader />)}

                    {/* ERRORS RENDERING */}
                    {Object.keys(errors).length > 0 && (
                        <div className=''>
                            {Object.entries(errors).map(([field, value], index) => {
                                return (
                                    <div className='flex gap-2 flex-wrap items-center -mt-2 mb-2' key={index}>
                                        <MdError color='red' />
                                        <p className='font-semibold text-brand-700 m-0'>{field}: </p>
                                        <p className='font-semibold text-red-500 m-0'>{value}</p>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </ModalBody>

                {/* BUTTON */}
                <ModalFooter className='shadow-3xl flex gap-3 items-center flex-wrap justify-start sm:justify-between shadow-shadow-500'>
                    <div className='mr-auto flex gap-3 flex-wrap items-center'>
                        <p className='cursor-pointer' onClick={onDelete}>
                            <FcEmptyTrash size={22} />
                        </p>

                        {
                            showJump &&
                            <Button className={`linear flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-sm sm:text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white`}>
                                <Link to={`/admin/other/trips?id=${selectedRow?.id || updatedRow?.id || ''}`} >
                                    Jump to {updatedRow?.id}
                                </Link>
                            </Button>

                        }
                    </div>

                    {columnType !== 'qr' &&
                        <div>
                            <Button className={`linear flex items-center justify-center rounded-xl px-4 py-2 text-sm sm:text-base text-white font-medium transition mx-3 duration-200 ${isEditingRow ? 'bg-red-500' : 'bg-brand-500'}`} onClick={toggleEdit}>
                                {isEditingRow ? 'Cancel Edit' : 'Edit'}
                            </Button>

                            <Button
                                className={`linear flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-sm sm:text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${isEditingRow ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-70'}`}
                                onClick={onSave}
                                isLoading={saveLoad}
                                loadingText="Saving"
                                spinnerPlacement='end'
                            >Update</Button>
                        </div>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default RowModal;
import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
  useInterval,
} from '@chakra-ui/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";


import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import axios from 'axios';

const { Modal } = chakraTheme.components

const theme = extendBaseTheme({
  components: {
    Modal,
  },
})

const App = () => {
  return (
    <ChakraBaseProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/admin/default" replace />} />
      </Routes>
    </ChakraBaseProvider>
  );
};

export default App;

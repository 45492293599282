// export const columnsDataDevelopment = [
//   {
//     Header: "NAME",
//     accessor: "name",
//   },
//   {
//     Header: "TECH",
//     accessor: "tech",
//   },
//   {
//     Header: "DATE",
//     accessor: "date",
//   },
//   {
//     Header: "PROGRESS",
//     accessor: "progress",
//   },
// ];

export const columnsDataCheck = [
  {
    Header: "PASSENGER NAME",
    accessor: "name",
  },
  {
    Header: "PICKUP",
    accessor: "pickup",
  },
  {
    Header: "DROPOFF",
    accessor: "dropoff",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

// export const columnsDataColumns = [
//   {
//     Header: "NAME",
//     accessor: "name",
//   },
//   {
//     Header: "PROGRESS",
//     accessor: "progress",
//   },
//   {
//     Header: "QUANTITY",
//     accessor: "quantity",
//   },
//   {
//     Header: "DATE",
//     accessor: "date",
//   },
// ];

// export const columnsDataComplex = [
//   {
//     Header: "NAME",
//     accessor: "name",
//   },
//   {
//     Header: "STATUS",
//     accessor: "status",
//   },
//   {
//     Header: "DATE",
//     accessor: "date",
//   },
//   {
//     Header: "PROGRESS",
//     accessor: "progress",
//   },
// ];

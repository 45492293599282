import React, { useEffect, useState } from 'react';
import Widget from 'components/widget/Widget';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { RiListSettingsFill } from 'react-icons/ri';
import { Button } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';
import { toast } from 'react-toastify';
import { Input, Box, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import axios from 'axios';
import '../../assets/css/ManageTable.css'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const Spinner = styled.div`
	margin: 16px auto;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 3.5px solid rgb(117, 81, 255);
	background: transparent;
	width: 40px;
	height: 40px;
	border-radius: 50%;
    text-align: center;
`;
const CustomLoader = () => (
    <div className='text-center p-[24px]'>
        <Spinner />
        {/* <div className='text-center text-brand-500'>Loading...</div> */}
    </div>
);

const VeticalLayout = ({ dataAPI, type, title }) => {
    const [data, setData] = useState() || [];
    const [originalData, setOriginalData] = useState();
    const [saveLoad, setSaveLoad] = useState(false);
    const [pending, setPending] = useState(true)
    const [render, setRender] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    // TITLES AND FIELDS
    const titlesAndFields = {
        management: [
            {
                title: 'Additional Pricings', fields: ['Price A', 'Price B', 'Price C'], accessors: ['Price_A', 'Price_B', 'Price_C']
            },
            {
                title: 'Distance Based Price in KM', fields: ['Distance Limit', 'Distance Upper Limit', 'Distance Lower Limit'], accessors: ['Distance_Limit', 'Distance_Upper_Limit_Cost', 'Distance_Lower_Limit_Cost']
            },
            {
                title: 'Time Based Price', fields: ['Day Time Schedule', 'Night Time Schedule', 'Day Time Price', 'Night Time Price'],
                accessors: ['Day_Time_Schedule', 'Night_Time_Schedule', 'Day_Time_Price', 'Night_Time_Price']
            }
        ],
        price: [
            {
                title, fields: ['Price'], accessors: ['Price']
            },
        ],
        qr_links: [
            { title: 'WEB', fields: ['Web'], accessors: ['Web'] },
            { title: 'IOS', fields: ['iOS'], accessors: ['iOS'] },
            { title: 'ANDROID', fields: ['Android'], accessors: ['Android'] }
        ]
    };

    // FETCHING DATA
    useEffect(() => {
        const getReponse = async () => {
            const accessToken = localStorage.getItem('access');
            try {
                const headers = {
                    'Authorization': `Bearer ${accessToken}`
                }

                const response = await axios(dataAPI, { headers })
                //     const newObj = {};
                //     Object.keys(obj).forEach(key => {
                //         const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1).replace(/_/g, '');
                //         newObj[modifiedKey] = obj[key];
                //     });
                //     return newObj;
                // });


                if (type === 'management') {
                    const formattedData = {
                        ...response.data[0],
                        Day_Time_Schedule: `${response.data[0].Start_Day_Time} - ${response.data[0].End_Day_Time}`,
                        Night_Time_Schedule: `${response.data[0].Start_Night_Time} - ${response.data[0].End_Night_Time}`
                    };

                    setData(formattedData)
                    setOriginalData(formattedData)
                    setPending(false)
                    return;
                }

                setData(response.data[0])
                setOriginalData(response.data[0])
                setPending(false)
            } catch (error) {
                console.log(error);
                setPending(false)
            }
        }
        getReponse()
    }, [render]);

    // EDITING CHANGES
    const handleInputChange = (column, accessor) => {
        const { value } = column.target;

        setData({
            ...data,
            [accessor]: value
        });

        setHasChanges(true);
    };

    // SAVING CHANGES
    const handleSaveChanges = async () => {
        const accessToken = localStorage.getItem('access');
        try {
            const editedFields = {};

            for (const key in data) {
                if (data.hasOwnProperty(key) && data[key] !== originalData[key]) {
                    editedFields[key] = data[key];
                }
            }

            if (Object.keys(editedFields).length === 0) {
                return;
            }

            const headers = {
                'Authorization': `Bearer ${accessToken}`
            }

            if (type === 'management') {
                const [startDayTime, endDayTime] = data.Day_Time_Schedule.split(' - ');
                const [startNightTime, endNightTime] = data.Night_Time_Schedule.split(' - ');

                data.Start_Day_Time = startDayTime;
                data.End_Day_Time = endDayTime;
                data.Start_Night_Time = startNightTime;
                data.End_Night_Time = endNightTime;

                delete data.Day_Time_Schedule;
                delete data.Night_Time_Schedule;
            }

            const savingData = await axios.put(`${dataAPI}${data.id ? `${data.id}/` : ''}`, data, { headers });

            setOriginalData({ ...originalData, ...editedFields });

            setHasChanges(false);
            toast('Data updated successfully.')
            setRender(!render)
        } catch (error) {
            toast('Error updating data.')
            setHasChanges(false);
            console.error('Error updating data:', error);
        }
    };

    return (
        <div>
            <div className='mt-5 mb-3'>
                <Widget
                    icon={<RiListSettingsFill className="h-7 w-7" />}
                    title={"Manage Data"}
                    subtitle={title}
                />
            </div>

            {/* {pending ? (
                <div className='mt-14'>
                    <CustomLoader />
                </div>
            ) : ( */}
            <div>
                {/* TITLE & FIELDS RENDERING */}
                <div className='flex flex-col gap-5 mt-10'>
                    {titlesAndFields[type]?.map((titleData, index) => (
                        <div
                            key={index}
                            className={`rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none px-6 py-8`}
                        >
                            <div className='mb-5 text-xl sm:text-2xl font-medium'>
                                <h2>{titleData?.title}:</h2>
                            </div>
                            <div className='flex gap-2 flex-wrap justify-start items-center'>
                                {titleData?.fields?.map((field, idx) => (
                                    <FormControl key={idx} className='flex flex-col flex-grow'>
                                        <FormLabel className='mb-1'>{field}</FormLabel>
                                        <Input
                                            type="text"
                                            name={field}
                                            className='rounded text-navy-700 p-2 text-md dark:text-white bg-lightPrimary dark:bg-navy-700 flex-1'
                                            placeholder='Enter your value'
                                            // value={data ? data[field.replace(/ /g, '_')] : ''}
                                            // value={data && data[]}
                                            value={data && data[titleData.accessors[idx]] !== undefined ? data[titleData.accessors[idx]] : ''}
                                            onChange={(e) => handleInputChange(e, titleData.accessors[idx])}
                                        />
                                        <FormErrorMessage>{/* Add error message here */}</FormErrorMessage>
                                    </FormControl>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* BUTTON RENDERING */}
                <div className='fixed bottom-8 md:bottom-6 z-50 right-6 shadow shadow-gray-800 rounded-xl'>
                    <Button
                        className={`linear rounded-xl bg-brand-500 px-4 py-2 text-xs sm:text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${hasChanges ? '' : 'pointer-events-none opacity-60'}`}
                        onClick={handleSaveChanges}
                        isLoading={saveLoad}
                        loadingText="Saving"
                        spinnerPlacement='end'
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
            {/* )} */}


        </div >
    );
};

export default VeticalLayout;
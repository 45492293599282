import VerticalLayout from 'components/verticalLayout'

const WCManagement = () => {
    const data = [
        {
            id: 1,
            tickBox: false,
            distanceLowerLimitCost: 10,
            priceA: 20,
            priceB: 30,
            priceC: 40,
            distanceUpperLimitCost: 50,
            distanceLimit: 100,
            startDayTime: '09:00',
            endDayTime: '17:00'
        },
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <VerticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/wc/" type="management" title="Wheelchair Management" />
            </div>
        </div>
    );
};

export default WCManagement;

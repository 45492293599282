import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@chakra-ui/react";

export default function SignIn() {
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [idError, setIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [handleLoad, setHandleLoad] = useState(false);
  const navigate = useNavigate()

  const removeToken = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
  }

  useEffect(() => {
    const verifyToken = async () => {
      const accessToken = localStorage.getItem('access');
      const refreshToken = localStorage.getItem('refresh');
      try {
        if (!accessToken && !refreshToken) {
          console.log("NOT AVAILABLE")
          removeToken()
          return;
        }

        console.log("AVAILABLE")

        const body = {
          'refresh': refreshToken
        };

        const checkToken = await axios.post('https://taxi2.thecaptaintaxis.com.au/token/refresh/', body);

        const { access } = checkToken.data;

        console.log(checkToken)

        if (access) {
          console.log("ENTER IF")
          navigate('/admin/default')
        } else {
          console.log("ENTER ELSE")
          removeToken()
          return;
        }
      } catch (error) {
        console.log(error);
        removeToken()
      }
    };

    verifyToken();
  }, []);


  const handleSignIn = async () => {
    setHandleLoad(true)
    try {
      // Validate id
      if (id.trim().length < 1) {
        setHandleLoad(false)
        setIdError('Invalid Id');
        return;
      } else {
        setIdError('');
      }

      // Validate password length
      if (password.trim().length < 1) {
        setHandleLoad(false)
        setPasswordError('Invalid Password');
        return;
      } else {
        setPasswordError('');
      }


      const loginData = {
        id,
        password
      };

      const response = await axios.post('https://taxi2.thecaptaintaxis.com.au/login/', loginData);

      console.log(response?.data);

      if (response?.status === 200 || response?.status === 201) {
        setPasswordError('');
        localStorage.setItem('access', response?.data['access']);
        localStorage.setItem('refresh', response?.data['refresh']);
        setHandleLoad(false)
        navigate('/admin/default');
      } else {
        setPasswordError('Something went wrong!');
        setHandleLoad(false)
      }
    } catch (error) {
      if (error) {
        console.error('Error:', error);
        setHandleLoad(false)
        setPasswordError(error?.response?.data?.error || 'Something went wrong!');
      } else {
        setPasswordError('Something went wrong!');
      }
    }
  };


  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter Id and password to sign in!
        </p>

        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}

        {/* Id */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Id*"
          placeholder="Id"
          id="id"
          type="text"
          state={idError && 'error'}
          error={idError}
          onChange={(e) => setId(e.target.value)}
        />
        {/* {idError && <p className="text-red-500 ml-1.5">{idError}</p>} */}

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Password"
          id="password"
          type="password"
          state={passwordError && 'error'}
          error={passwordError}
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* {passwordError && <p className="text-red-500 ml-1.5">{passwordError}</p>} */}

        {/* Checkbox */}
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}

        <Button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" onClick={handleSignIn}
          isLoading={handleLoad}
        >
          Sign In
        </Button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}

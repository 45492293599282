import { useState } from 'react';
import { IconButton, Image, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { IoDownloadOutline, IoImageOutline } from 'react-icons/io5';


const DownloadImg = ({ imgUrl, title }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openImageModal = () => {
        setIsOpen(true);
    };

    const closeImageModal = () => {
        setIsOpen(false);
    };

    const downloadBlobImg = async () => {
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        const temporaryUrl = URL.createObjectURL(blob);

        // Create a downloadable link element (consider adding to UI)
        const downloadLink = document.createElement('a');
        downloadLink.href = temporaryUrl;
        downloadLink.download = `${title} Image.jpg`;
        downloadLink.click();
    }

    return (
        <div className='flex gap-4 flex-wrap items-center'>
            <a href={imgUrl} className='bg-black' target='_blank'>
                <Image src={imgUrl} alt={title} borderRadius='full' fallbackSrc='https://via.placeholder.com/150' boxSize='150px' objectFit='cover' />
            </a>
            <IconButton onClick={openImageModal} aria-label="View Image" icon={<IoImageOutline />} fontSize={25} />
            <IconButton onClick={downloadBlobImg} aria-label="Download Image" icon={<IoDownloadOutline color='blue' />} fontSize={22} />
            <Modal isOpen={isOpen} onClose={closeImageModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={0}>
                        <Image src={imgUrl} alt={title} fallbackSrc='https://via.placeholder.com/150' objectFit='cover' />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default DownloadImg;
import React, { useState } from 'react';
import ManageTable from 'components/manageTable';
import VeticalLayout from 'components/verticalLayout';

const FuelPrice = () => {
    const data = [
        {
            id: 1,
            price: 15
        }
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <VeticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/fuel/" type="price" title="Fuel Price/km" />
            </div>
        </div>
    );
};

export default FuelPrice;

import axios from "axios";
import ManageTable from "components/manageTable";
import { useEffect } from "react";

const EmailNoti = () => {
    const data = [
        {
            id: 1,
            emailNotifications: 'user123@example.com',
        },
        {
            id: 2,
            emailNotifications: 'jane@example.com',
        },
    ];

    // useEffect(() => {
    //     const getReponse = async () => {
    //         try {
    //             const response = await axios("https://taxi2.thecaptaintaxis.com.au/email/")

    //             const modifiedData = response.data.map(obj => {
    //                 const newObj = {};
    //                 Object.keys(obj).forEach(key => {
    //                     const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1).replace(/_/g, '');
    //                     newObj[modifiedKey] = obj[key];
    //                 });
    //                 return newObj;
    //             });

    //             console.log("response", response)

    //             console.log("modifiedData", modifiedData)

    //             // setData(modifiedData)
    //             // setPending(false)
    //         } catch (error) {
    //             console.log(error);
    //             // setPending(false)
    //         }
    //     }
    //     getReponse()
    // }, [])

    return (
        <div>
            <div className='mt-5 mb-3'>
                <ManageTable dataAPI="https://taxi2.thecaptaintaxis.com.au/email/" title="Email Notifications" columnType="noti" pagination="true" />
            </div>
        </div>
    );
};

export default EmailNoti;
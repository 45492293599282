// Custom components
import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

function InputField(props) {
  const { label, id, extra, type, placeholder, variant, state, disabled, error, onChange } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
      >
        {label}
      </label>
      {/* <input
        disabled={disabled}
        type={type}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        className={`my-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
          ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
          : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
      /> */}

      <div className="relative">
        <input
          disabled={disabled}
          type={type === "text" ? 'text' : (
            showPassword ? 'text' : 'password'
          )} // Toggle between text and password type
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          className={`my-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
        />
        {type === "password" && (
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-4 text-gray-500 dark:text-white"
          >
            {showPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon based on password visibility state */}
          </button>
        )}
      </div>

      <p className="text-red-500 ml-1.5 text-base">{error}</p>
    </div>
  );
}

export default InputField;

import VerticalLayout from 'components/verticalLayout'

const SuvManagement = () => {
    return (
        <div>
            <div className='mt-5 mb-3'>
                <VerticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/suv/" type="management" title="SUV Management" />
            </div>
        </div>
    );
};

export default SuvManagement;

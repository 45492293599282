import ManageTable from "components/manageTable";

const NumberNoti = () => {
    const data = [
        {
            id: 1,
            numberNotifications: '+61412345678',
        },
        {
            id: 2,
            numberNotifications: '+61423456789',
        },
        {
            id: 3,
            numberNotifications: '+61434567890',
        },
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <ManageTable dataAPI="https://taxi2.thecaptaintaxis.com.au/number/" title="Number Notifications" columnType="noti" pagination="true" />
            </div>
        </div>
    );
};

export default NumberNoti;
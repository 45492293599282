import ManageTable from 'components/manageTable';

const Drivers = () => {
    const data = [
        {
            id: 1,
            First_Name: 'John',
            Username: 'johndoe123',
            DOB: '1990-05-15',
            Street_Address: '123 Main Street',
            Suburb: 'Sydney',
            Post_Code: '2000',
            Mobile_Number: '123-456-7890',
            Email_Address: 'john@example.com',
            Card_Holder: 'John Doe',
            Account_Number: '1234567890',
            Bank_Name: 'Example Bank',
            Aus_Citi_Medi: true,
            Self_Picture: 'https://example.com/self_picture.jpg',
            Driver_License_Front: 'https://example.com/driver_license_front.jpg',
            Driver_License_Back: 'https://example.com/driver_license_back.jpg',
            Police_Check: 'https://example.com/police_check.jpg',
            English_Language: 'https://example.com/english_language.jpg',
            Other_ID: 'https://example.com/other_id.jpg',
            VEVO_Papers: 'https://example.com/vevo_papers.jpg',
            Passport_ID: 'https://example.com/passport_id.jpg',
        },
        // Add more objects with similar structure for additional data entries
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <ManageTable dataAPI="https://taxi2.thecaptaintaxis.com.au/drivers/" columnType="driver" title="Drivers" pagination={true} />
            </div>
        </div>
    );
};

export default Drivers;

import VerticalLayout from 'components/verticalLayout'

const SedanManagement = () => {
    return (
        <div>
            <div className='mt-5 mb-3'>
                <VerticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/sedan/" type="management" title="Sedan Management" />
            </div>
        </div>
    );
};

export default SedanManagement;

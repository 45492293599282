import DownloadImg from "components/downloadImage";
import { BiTrip } from "react-icons/bi";

const columnsConfig = {
    passengerColumns: (title, handlePassengerTrips) => [
        {
            name: 'ID',
            selector: row => row.id,
            width: '60px',
            accessor: 'id',
            sortable: true,
            cell: row => <div className="text-[1rem] font-bold">{row.id}:</div>
        },
        {
            name: 'Passenger Name',
            selector: row => row.Passenger_Name,
            accessor: 'Passenger_Name',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Passenger_Name}
                    onChange={e => handleInputChange(e, row.id, 'Passenger_Name')}
                />
            )
        },

        {
            name: 'Trips',
            width: '60px',
            accessor: 'trips',
            cell: row => (
                <button onClick={() => handlePassengerTrips(row)}>
                    <BiTrip size={18} />
                </button>
            )
        },

        {
            name: 'Contact Number',
            selector: row => row.Contact_Number,
            accessor: 'Contact_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Contact_Number}
                    onChange={e => handleInputChange(e, row.id, 'Contact_Number')}
                />
            )
        },
        {
            name: 'Email Address',
            selector: row => row.Email,
            accessor: 'Email',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="email"
                    value={row.Email}
                    onChange={e => handleInputChange(e, row.id, 'Email')}
                />
            )
        },
    ],
    tripColumns: (title) => [
        {
            name: 'ID',
            selector: row => row.id,
            accessor: 'id',
            width: '60px',
            sortable: true,
            cell: row => <div className="text-[1rem] font-bold">{row.id}:</div>
        },
        {
            name: 'Book For Later',
            selector: row => row.Book_For_Later,
            accessor: 'Book_For_Later',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Book_For_Later}
                    onChange={e => handleInputChange(e, row.id, 'Book_For_Later')}
                />
            )
        },
        {
            name: 'Travelling Date Time',
            selector: row => row.Date_Time,
            accessor: 'Date_Time',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="datetime-local"
                    value={row?.Date_Time}
                    onChange={e => handleInputChange(e, row.id, 'Date_Time')}
                />
            )
        },

        {
            name: 'Pickup Location',
            selector: row => row.Pickup_Location,
            accessor: 'Pickup_Location',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Pickup_Location}
                    onChange={e => handleInputChange(e, row.id, 'Pickup_Location')}
                />
            )
        },
        {
            name: 'Dropoff Location',
            selector: row => row.Dropoff_Location,
            accessor: 'Dropoff_Location',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Dropoff_Location}
                    onChange={e => handleInputChange(e, row.id, 'Dropoff_Location')}
                />
            )
        },
        {
            name: 'Price',
            selector: row => row.Price,
            accessor: 'Price',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Price}
                    onChange={e => handleInputChange(e, row.id, 'Price')}
                />
            )
        },
        {
            name: 'Payment Method',
            selector: row => row.Payment_Method,
            accessor: 'Payment_Method',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    className={
                        row.Payment_Method?.toLowerCase() === 'cash' ? '!bg-[#ffd700] text-white' :
                            row.Payment_Method?.toLowerCase() === 'cab charge' ? '!bg-[#ffa500] text-white' :
                                row.Payment_Method?.toLowerCase() === 'credit card' ? '!bg-[#00ff00] text-white' :
                                    row.Payment_Method?.toLowerCase() === 'ttss' ? '!bg-[#C2DFFF] text-white' :
                                        'red'
                    }
                    value={row.Payment_Method}
                    onChange={e => handleInputChange(e, row.id, 'Payment_Method')}
                />
            )
        },
        {
            name: 'Paid Via TTSS',
            selector: row => row.Paid_Via_TTSS,
            accessor: 'Paid_Via_TTSS',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Paid_Via_TTSS}
                    onChange={e => handleInputChange(e, row.id, 'Paid_Via_TTSS')}
                />
            )
        },
        {
            name: 'Driver Instructions',
            selector: row => row.Driver_Instructions,
            accessor: 'Driver_Instructions',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Driver_Instructions}
                    onChange={e => handleInputChange(e, row.id, 'Driver_Instructions')}
                />
            )
        },
    ],
    notiColumns: (title) => [
        {
            name: 'ID',
            selector: row => row.id,
            accessor: 'id',
            width: '60px',
            sortable: true,
            cell: row => <div className="text-[1rem] font-bold">{row.id}:</div>
        },
        {
            name: title,
            selector: row => row.email || row.number,
            accessor: title.toLowerCase().includes('email') ? 'email' : 'number',
            sortable: true,
            cell: (row, handleInputChange) => (
                row.email ?
                    <input
                        type="email"
                        value={row.email}
                        onChange={e => handleInputChange(e, row.id, 'email')}
                        placeholder="Enter Email"
                    /> :
                    <input
                        type="number"
                        value={row.number}
                        onChange={e => handleInputChange(e, row.id, 'number')}
                        placeholder="Enter Number"
                    />
            )
        },
    ],
    qrColumns: (title) => [
        {
            name: 'ID',
            selector: row => row.id,
            accessor: 'id',
            width: '60px',
            sortable: true,
            cell: row => <div className="text-[1rem] font-bold">{row.id}:</div>
        },
        {
            name: 'Date Time',
            selector: row => row.Date_Time,
            accessor: 'Date_Time',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Date_Time}
                    onChange={e => handleInputChange(e, row.id, 'Date_Time')}
                />
            )
        },
        {
            name: 'Device Type',
            selector: row => row.Device_Type,
            accessor: 'Device_Type',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Device_type}
                    className={
                        row.Device_Type?.toLowerCase() === 'web' ? '!bg-blue-200 text-blue-600' :
                            row.Device_Type?.toLowerCase() === 'android' ? '!bg-yellow-200 text-yellow-900' :
                                row.Device_Type?.toLowerCase() === 'ios' ? '!bg-green-200 text-green-900' :
                                    'bg-gray-200 text-gray-900'
                    }
                    onChange={e => handleInputChange(e, row.id, 'Device_Type')}
                />
            )
        },
        {
            name: 'Browser',
            selector: row => row.Browser,
            accessor: 'Browser',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Browser}
                    onChange={e => handleInputChange(e, row.id, 'Browser')}
                />
            )
        },
        {
            name: 'Country',
            selector: row => row.Country,
            accessor: 'Country',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Country}
                    onChange={e => handleInputChange(e, row.id, 'Country')}
                />
            )
        },
    ],
    driverColumns: (title) => [
        {
            name: 'ID',
            selector: row => row.id,
            accessor: 'id',
            width: '60px',
            sortable: true,
            cell: row => <div className="text-[1rem] font-bold">{row.id}:</div>
        },
        {
            name: 'First Name',
            selector: row => row.First_Name,
            accessor: 'First_Name',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.First_Name}
                    onChange={e => handleInputChange(e, row.id, 'First_Name')}
                />
            ),
        },
        {
            name: 'Last Name',
            selector: row => row.Last_Name,
            accessor: 'Last_Name',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Last_Name}
                    onChange={e => handleInputChange(e, row.id, 'Last_Name')}
                />
            ),
        },
        {
            name: 'Username',
            selector: row => row.Username,
            accessor: 'Username',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Username}
                    onChange={e => handleInputChange(e, row.id, 'Username')}
                />
            ),
        },
        {
            name: 'Full Name',
            selector: row => row.Full_Name,
            accessor: 'Full_Name',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Full_Name}
                    onChange={e => handleInputChange(e, row.id, 'Full_Name')}
                />
            ),
        },

        {
            name: 'DOB',
            selector: row => row.DOB,
            accessor: 'DOB',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="date"
                    value={row.DOB}
                    onChange={e => handleInputChange(e, row.id, 'DOB')}
                />
            ),
        },
        {
            name: 'Sex',
            selector: row => row.Sex,
            accessor: 'Sex',
            sortable: true,
            cell: (row, handleInputChange) => (
                <select
                    name="Sex"
                    required
                    value={row.Sex}
                    onChange={e => handleInputChange(e, row.id, 'Sex')}
                    defaultValue={'default'}
                >
                    <option value="default" disabled>Select Sex</option>
                    <option value="unknown" hidden>Unknown</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            )
        },
        {
            name: 'Street Address',
            selector: row => row.Street_Address,
            accessor: 'Street_Address',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Street_Address}
                    onChange={e => handleInputChange(e, row.id, 'Street_Address')}
                />
            ),
        },
        {
            name: 'Suburb',
            selector: row => row.Suburb,
            accessor: 'Suburb',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Suburb}
                    onChange={e => handleInputChange(e, row.id, 'Suburb')}
                />
            ),
        },
        {
            name: 'Post Code',
            selector: row => row.Post_Code,
            accessor: 'Post_Code',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Post_Code}
                    onChange={e => handleInputChange(e, row.id, 'Post_Code')}
                />
            ),
        },
        {
            name: 'Mobile Number',
            selector: row => row.Mobile_Number,
            accessor: 'Mobile_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Mobile_Number}
                    onChange={e => handleInputChange(e, row.id, 'Mobile_Number')}
                />
            ),
        },
        {
            name: 'Email Address',
            selector: row => row.Email_Address,
            accessor: 'Email_Address',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="email"
                    value={row.Email_Address}
                    onChange={e => handleInputChange(e, row.id, 'Email_Address')}
                />
            ),
        },
        {
            name: 'Card Holder',
            selector: row => row.Card_Holder,
            accessor: 'Card_Holder',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Card_Holder}
                    onChange={e => handleInputChange(e, row.id, 'Card_Holder')}
                />
            ),
        },
        {
            name: 'Account Number',
            selector: row => row.Account_Number,
            accessor: 'Account_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Account_Number}
                    onChange={e => handleInputChange(e, row.id, 'Account_Number')}
                />
            ),
        },

        // BANK ACCOUNT
        {
            name: 'Bank Name',
            selector: row => row.Bank_Name,
            accessor: 'Bank_Name',
            sortable: true,
            cell: (row, handleInputChange) => (
                <select
                    name="Bank_Name"
                    required
                    value={row.Bank_Name}
                    onChange={e => handleInputChange(e, row.id, 'Bank_Name')}
                    defaultValue={'default'}
                >
                    <option value="default" disabled>Select Bank Name</option>
                    <option value="unknown" hidden>Unknown</option>
                    <option value="AMP Bank">AMP Bank</option>
                    <option value="ANZ">ANZ</option>
                    <option value="Bank Australia">Bank Australia</option>
                    <option value="Bank of Melbourns">Bank of Melbourns</option>
                    <option value="Bendigo Bank">Bendigo Bank</option>
                    <option value="Beyond Bank Australia">Beyond Bank Australia</option>
                    <option value="Commonwealth Bank">Commonwealth Bank</option>
                    <option value="Deefencr Bank">Deefencr Bank</option>
                    <option value="Delphi BAnk">Delphi BAnk</option>
                    <option value="Hume Bank">Hume Bank</option>
                    <option value="IMB Bank">IMB Bank</option>
                    <option value="Macuaire Bank">Macuaire Bank</option>
                    <option value="ME Bank">ME Bank</option>
                    <option value="NAB Bank">NAB Bank</option>
                    <option value="St George Bank">St George Bank</option>
                    <option value="Suncorp Group">Suncorp Group</option>
                    <option value="Teachers Mutual Bank">Teachers Mutual Bank</option>
                    <option value="Victoria Mutual Bank">Victoria Mutual Bank</option>
                    <option value="Westpac Bank">Westpac Bank</option>
                    <option value="HSBC">HSBC</option>
                    <option value="ING">ING</option>
                    <option value="MyState Bank">MyState Bank</option>
                    <option value="Unity Bank">Unity Bank</option>
                    <option value="Auswide Bank">Auswide Bank</option>
                    <option value="Bank First">Bank First</option>
                    <option value="BNK Banking Corp">BNK Banking Corp</option>
                    <option value="Central West Credit Union Limited">Central West Credit Union Limited</option>
                    <option value="Australian Military Bank">Australian Military Bank</option>
                    <option value="Border Bank">Border Bank</option>
                    <option value="Adelaide Bank">Adelaide Bank</option>
                    <option value="Australia and New Zealand Banking Group">Australia and New Zealand Banking Group</option>
                    <option value="Bankwest">Bankwest</option>
                    <option value="BOQ">BOQ</option>
                </select>
            ),
        },
        {
            name: 'BSB Number',
            selector: row => row.BSB_Number,
            accessor: 'BSB_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.BSB_Number}
                    onChange={e => handleInputChange(e, row.id, 'BSB_Number')}
                />
            ),
        },
        {
            name: 'ABN',
            selector: row => row.ABN,
            accessor: 'ABN',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.ABN}
                    onChange={e => handleInputChange(e, row.id, 'ABN')}
                />
            ),
        },

        // OPERATOR
        {
            name: 'Operator Name',
            selector: row => row.Operator_Name,
            accessor: 'Operator_Name',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Operator_Name}
                    onChange={e => handleInputChange(e, row.id, 'Operator_Name')}
                />
            ),
        },
        {
            name: 'Operator Number',
            selector: row => row.Operator_Number,
            accessor: 'Operator_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Operator_Number}
                    onChange={e => handleInputChange(e, row.id, 'Operator_Number')}
                />
            ),
        },

        // EMERGENCY NUMBER
        {
            name: 'Emergency Number',
            selector: row => row.Emergency_Number,
            accessor: 'Emergency_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Emergency_Number}
                    onChange={e => handleInputChange(e, row.id, 'Emergency_Number')}
                />
            ),
        },



        // CAR 
        {
            name: 'Fleets Taxi',
            selector: row => row.Fleets_Taxi,
            accessor: 'Fleets_Taxi',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Fleets_Taxi}
                    onChange={e => handleInputChange(e, row.id, 'Fleets_Taxi')}
                />
            ),
        },
        {
            name: 'Vehicle Color',
            selector: row => row.Vehicle_Color,
            accessor: 'Vehicle_Color',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Vehicle_Color}
                    onChange={e => handleInputChange(e, row.id, 'Vehicle_Color')}
                />
            ),
        },
        {
            name: 'Vehicle Features',
            selector: row => row.Vehicle_Features,
            accessor: 'Vehicle_Features',
            sortable: true,
            cell: (row, handleInputChange) => (
                <select
                    name="Vehicle_Features"
                    value={row.Vehicle_Features}
                    defaultValue="default"
                    required
                    onChange={e => handleInputChange(e, row.id, 'Vehicle_Features')}
                >
                    <option value="default" disabled>Select Features</option>
                    <option value="Unknown" hidden>Unknown</option>
                    <option value="Sedan Driver">Sedan Driver</option>
                    <option value="SUV Driver 7 Seats">SUV Driver 7 Seats</option>
                    <option value="Maxi Driver 11 Seats">Maxi Driver 11 Seats</option>
                    <option value="NDIS">NDIS</option>
                    <option value="Two Wheelchair Spaces">Two Wheelchair Spaces</option>
                </select>
            ),
        },
        {
            name: 'Vehicles Model',
            selector: row => row.Vehicles_Model,
            accessor: 'Vehicles_Model',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Vehicles_Model}
                    onChange={e => handleInputChange(e, row.id, 'Vehicles_Model')}
                />
            ),
        },
        {
            name: 'Vehicles VIN',
            selector: row => row.Vehicles_VIN,
            accessor: 'Vehicles_VIN',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Vehicles_VIN}
                    onChange={e => handleInputChange(e, row.id, 'Vehicles_VIN')}
                />
            ),
        },
        {
            name: 'Plate Number',
            selector: row => row.Plate_Number,
            accessor: 'Plate_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Plate_Number}
                    onChange={e => handleInputChange(e, row.id, 'Plate_Number')}
                />
            ),
        },
        {
            name: 'Registration Expiry Date',
            selector: row => row.Registration_Expiry_Date,
            accessor: 'Registration_Expiry_Date',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Registration_Expiry_Date}
                    onChange={e => handleInputChange(e, row.id, 'Registration_Expiry_Date')}
                />
            ),
        },
        {
            name: 'Taxi Made',
            selector: row => row.Taxi_Made,
            accessor: 'Taxi_Made',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="text"
                    value={row.Taxi_Made}
                    onChange={e => handleInputChange(e, row.id, 'Taxi_Made')}
                />
            ),
        },
        {
            name: 'Etag Number',
            selector: row => row.Etag_Number,
            accessor: 'Etag_Number',
            sortable: true,
            cell: (row, handleInputChange) => (
                <input
                    type="number"
                    value={row.Etag_Number}
                    onChange={e => handleInputChange(e, row.id, 'Etag_Number')}
                />
            ),
        },


        // IMAGES
        // AUTHORITY CARD
        {
            name: 'Authority Card Back',
            selector: row => row.Authority_Card_Back,
            accessor: 'Authority_Card_Back',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Authority_Card_Back} title="Aus Citi Medi" />
            ),
        },
        {
            name: 'Authority Card Front',
            selector: row => row.Authority_Card_Front,
            accessor: 'Authority_Card_Front',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Authority_Card_Front} title="Aus Citi Medi" />
            ),
        },

        {
            name: 'Driving History',
            selector: row => row.Driving_History,
            accessor: 'Driving_History',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Driving_History} title="Aus Citi Medi" />
            ),
        },
        {
            name: 'English Proficiency (Optional)',
            selector: row => row.English_Language,
            accessor: 'English_Language',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.English_Language} title="Aus Citi Medi" />
            ),
        },
        {
            name: 'Self Picture',
            selector: row => row.Self_Picture,
            accessor: 'Self_Picture',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Self_Picture} title="Self Picture" />
            ),
        },
        {
            name: 'Driver License Front',
            selector: row => row.Driver_License_Front,
            accessor: 'Driver_License_Front',
            sortable: true,
            fileInput: true,
            // Assume driverLicenseFront is a URL to the image
            cell: row => (
                <DownloadImg imgUrl={row.Driver_License_Front} title="Driver License Front" />
            ),
        },
        {
            name: 'Driver License Back',
            selector: row => row.Driver_License_Back,
            accessor: 'Driver_License_Back',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Driver_License_Back} title="Driver License Back" />
            ),
        },
        {
            name: 'Police Check',
            selector: row => row.Police_Check,
            accessor: 'Police_Check',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Police_Check} title="Police Check" />
            ),
        },
        // {
        //     name: 'Other ID',
        //     selector: row => row.Other_ID,
        //     accessor: 'Other_ID',
        //     sortable: true,
        //     fileInput: true,
        //     cell: row => (
        //         <DownloadImg imgUrl={row.Other_ID} title="Other ID" />
        //     ),
        // },
        {
            name: 'Medicare',
            selector: row => row.Aus_Citi_Medi,
            accessor: 'Aus_Citi_Medi',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Aus_Citi_Medi} title="Aus Citi Medi" />
            ),
        },
        {
            name: 'Vevo Papers',
            selector: row => row.Vevo_Papers,
            accessor: 'Vevo_Papers',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Vevo_Papers} title="Vevo Papers" />
            ),
        },
        {
            name: 'Passport ID',
            selector: row => row.Passport_ID,
            accessor: 'Passport_ID',
            sortable: true,
            fileInput: true,
            cell: row => (
                <DownloadImg imgUrl={row.Passport_ID} title="Passport ID" />
            ),
        },

    ]
};

export default columnsConfig
import ManageTable from "components/manageTable";

const QRReporting = () => {
    const data = [
        {
            id: 1,
            // dateTime: '2024-03-26, 05:00',
            Device_type: 'Android',
            device_count: 9
        },
        {
            id: 2,
            // dateTime: '2024-03-25, 06:30',
            Device_type: 'iOS',
            device_count: 2
        },
        {
            id: 3,
            // dateTime: '2024-03-26, 10:40',
            Device_type: 'Web',
            device_count: 15
        },
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <ManageTable dataAPI="https://taxi2.thecaptaintaxis.com.au/qr_hits" title="QR Reporting" columnType="qr" pagination="true" />
            </div>
        </div>
    );
};

export default QRReporting;
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";

const PieChartCard = ({ pieData }) => {
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Payment Methods
          </h4>
        </div>

        {/* <div className="mb-6 flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div> */}
      </div>

      <div className="mb-auto mt-5 flex h-[220px] w-full items-center justify-center">
        <PieChart options={pieChartOptions} series={pieData} />
      </div>

      {/* <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#FFD700]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Cash</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {pieData[0] || 0}
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#00FF00]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Credit Card</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {pieData[1] || 0}
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#FFA500]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Cab Charge</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {pieData[2] || 0}
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#FF0500]" />
            <p className="ml-1 text-sm font-normal text-gray-600">TTSS</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {pieData[3] || 0}
          </p>
        </div>

      </div> */}


      <div className="flex flex-row flex-wrap gap-3 justify-center sm:justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        {pieData?.map((percentage, index) => (
          <>
            <div className="flex flex-col items-center justify-center" key={pieChartOptions?.labels[index]}>
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full" style={{ backgroundColor: pieChartOptions?.colors[index] }} />
                <p className="ml-1 text-sm font-normal text-gray-600">{pieChartOptions?.labels[index]}</p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                {percentage}{percentage > 0 && '%'}
              </p>
            </div>
            {index !== pieData?.length - 1 && (
              <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />
            )}
          </>
        ))}
      </div>


    </Card>
  );
};

export default PieChartCard;

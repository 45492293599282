import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
	margin: 16px auto;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 3.5px solid rgb(117, 81, 255);
	background: transparent;
	width: 40px;
	height: 40px;
	border-radius: 50%;
`;

const CustomLoader = () => (
    <div style={{ padding: '24px' }}>
        <Spinner />
        <div className='text-center text-brand-500'>Loading...</div>
    </div>
);

export default CustomLoader;
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import axios from "axios";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [filteredRoutes, setFilteredRoutes] = React.useState(routes);

  const navigate = useNavigate()

  const removeToken = () => {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    navigate('/auth/sign-in')
  }

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const accessToken = localStorage.getItem('access');
        const refreshToken = localStorage.getItem('refresh');
        if (!accessToken && !refreshToken) {
          removeToken()
          return;
        }


        const body = {
          'refresh': refreshToken
        };

        const checkToken = await axios.post('https://taxi2.thecaptaintaxis.com.au/token/refresh/', body);

        const { access } = checkToken.data;


        if (access) {
          // navigate('/admin/default')
          const filtered = routes.filter(route => route.layout !== "/auth"); // Filter out routes with layout "/auth"
          localStorage.setItem('access', access)
          setFilteredRoutes(filtered);
        } else {
          removeToken()
          return;
        }
      } catch (error) {
        console.log(error);
        removeToken()
      }
    };

    verifyToken();
  }, []);


  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(filteredRoutes); // Pass filteredRoutes here
  }, [location.pathname]);
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.children) {
        return route.children.map((childRoute, childKey) => (
          childRoute.children ? (childRoute.children.map((childRoute2, childKey2) => (
            <Route
              key={childKey2}
              path={`${route.path}/${childRoute.path}/${childRoute2.path}`}
              element={childRoute2.component}
            />
          ))) :
            (
              <Route
                key={childKey}
                path={`${route.path}/${childRoute.path}`}
                element={childRoute.component}
              />
            )
        ));
      } else {
        return (
          <Route
            key={key}
            path={`${route.path}`}
            element={route.component}
          />
        );
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              secondary={getActiveNavbar(filteredRoutes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(filteredRoutes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
// chakra imports

import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react";

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // State to hold the truncated route name
  // const [truncatedName, setTruncatedName] = useState('');

  // // Function to truncate the name based on available width
  // const truncateName = (route) => {
  //   const element = document.getElementById("routeName");
  //   if (element) {
  //     const availableWidth = element.offsetWidth;
  //     const nameWidth = element.scrollWidth;
  //     if (nameWidth > availableWidth) {
  //       // Calculate how many characters can fit in the available width
  //       const charactersToFit = Math.floor(
  //         (availableWidth / nameWidth) * route.name.length
  //       );
  //       // Truncate the name and append ellipsis
  //       setTruncatedName(route.name.slice(0, charactersToFit) + "...");
  //       return route.name.slice(0, charactersToFit) + "..."
  //     } else {
  //       setTruncatedName(route.name);
  //       return route
  //     }
  //   }
  // };

  const activeRoute = (route) => {
    if (route.children) {
      for (let childRoute of route.children) {
        if (activeRoute(childRoute)) {
          return true;
        }
      }
      return false;
    } else {
      return location.pathname.includes(route.path);
    }
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin"
        //  ||
        // route.layout === "/auth" ||
        // route.layout === "/rtl"
      ) {
        // IF THERE IS ANY SUBMENU
        if (route.children) {
          return (
            <Accordion key={index} allowToggle>
              <AccordionItem>
                <div>
                  {/* MENU NAME */}
                  <div className="relative mb-3 flex justify-between hover:cursor-pointer">
                    <AccordionButton className="flex" _expanded={{ bg: '', padding: "" }}>
                      <li
                        className="my-[3px] flex cursor-pointer items-center px-8"
                      >
                        <span
                          className={`${activeRoute(route) === true
                            ? "font-bold text-brand-300 dark:text-white"
                            : "font-medium text-gray-600"
                            }`}
                        >
                          {route.icon ? route.icon : <DashIcon />}{" "}
                        </span>
                        <p
                          className={`leading-1 ml-4 flex ${activeRoute(route) === true
                            ? "font-bold text-navy-700 dark:text-white"
                            : "font-medium text-gray-600"
                            }`}
                        >
                          {route.name.length > 14 ? `${route.name.slice(0, 15)}...` : route.name}
                        </p>
                      </li>
                      {activeRoute(route) ? (
                        <div className="absolute right-0 h-9 w-1 rounded-lg bg-brand-100 dark:bg-brand-50" />
                      ) : null}
                      <AccordionIcon className="absolute right-5" />
                    </AccordionButton>
                  </div>

                  {/* SUBMENU RENDERING */}
                  <AccordionPanel pb={2}>
                    {route.children.map((childRoute, childIndex) => (

                      // IF NESTED SUBMENU
                      childRoute.children ? (
                        <Accordion key={childIndex} allowToggle>
                          <AccordionItem>
                            <div>
                              {/* NESTED SUBMENU NAME */}
                              <div className="relative mb-3 flex hover:cursor-pointer">
                                <AccordionButton className="flex">
                                  <li
                                    className="my-[3px] flex cursor-pointer items-center px-12"
                                  >
                                    <span
                                      className={`${activeRoute(childRoute) === true
                                        ? "font-bold text-brand-300 dark:text-white"
                                        : "font-medium text-gray-600"
                                        }`}
                                    >
                                      {childRoute.icon ? childRoute.icon : <DashIcon />}{" "}
                                    </span>
                                    <p
                                      className={`leading-1 ml-4 flex ${activeRoute(childRoute) === true
                                        ? "font-bold text-navy-700 dark:text-white"
                                        : "font-medium text-gray-600"
                                        }`}
                                    >
                                      {childRoute.name}
                                    </p>
                                  </li>
                                  {activeRoute(childRoute) ? (
                                    <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-300 dark:bg-brand-200" />
                                  ) : null}
                                  <AccordionIcon className="absolute right-5" />
                                </AccordionButton>
                              </div>

                              {/* NESTED SUBMENU RENDERING */}
                              <AccordionPanel pb={4}>
                                {
                                  childRoute.children.map((childRoute2, childIndex2) => (
                                    <Link
                                      key={childIndex2}
                                      to={`${route.layout}/${route.path}/${childRoute.path}/${childRoute2.path}`}
                                    >
                                      <div className="relative mb-3 flex hover:cursor-pointer">
                                        <li
                                          className="my-[3px] flex cursor-pointer items-center pl-16"
                                        >
                                          <span
                                            className={`${activeRoute(childRoute2) === true
                                              ? "font-bold text-brand-500 dark:text-white"
                                              : "font-medium text-gray-600"
                                              }`}
                                          >
                                            {childRoute2.icon ? childRoute2.icon : <DashIcon />}{" "}
                                          </span>
                                          <p
                                            className={`leading-1 ml-4 flex ${activeRoute(childRoute2) === true
                                              ? "font-bold text-navy-700 dark:text-white"
                                              : "font-medium text-gray-600"
                                              }`}
                                          >
                                            {childRoute2.name}
                                          </p>
                                        </li>
                                        {activeRoute(childRoute2) ? (
                                          <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                                        ) : null}
                                      </div>
                                    </Link>
                                  ))
                                }
                              </AccordionPanel>
                            </div>
                          </AccordionItem>
                        </Accordion>
                      )
                        : // NO OTHER SUBMENU 
                        (
                          <Link
                            key={childIndex}
                            // to={`${route.layout + "/" + `${route.path}` + `${childRoute.layout}`}`}
                            to={`${route.layout}/${route.path}/${childRoute.path}`}
                          >
                            <div className="relative mb-2 flex hover:cursor-pointer">
                              <li
                                className="my-[3px] flex cursor-pointer items-center px-12"
                              >
                                <span
                                  className={`${activeRoute(childRoute) === true
                                    ? "font-bold text-brand-300 dark:text-white"
                                    : "font-medium text-gray-600"
                                    }`}
                                >
                                  {childRoute.icon ? childRoute.icon : <DashIcon />}{" "}
                                </span>
                                <p
                                  className={`leading-1 ml-4 flex ${activeRoute(childRoute) === true
                                    ? "font-bold text-navy-700 dark:text-white"
                                    : "font-medium text-gray-600"
                                    }`}
                                >
                                  {childRoute.name}
                                </p>
                              </li>
                              {activeRoute(childRoute) ? (
                                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                              ) : null}
                            </div>
                          </Link>
                        )
                    ))}
                  </AccordionPanel>
                </div>
              </AccordionItem >
            </Accordion >
          );
        } else {
          return (
            <Link key={index} to={`${route.layout}/${route.path}`}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                >
                  <span
                    className={`${activeRoute(route) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                      }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${activeRoute(route) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                      }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          );
        }
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;

// const createLinks = (routes) => {
//   return routes.map((route, index) => {
//     if (
//       route.layout === "/admin" ||
//       route.layout === "/auth" ||
//       route.layout === "/rtl"
//     ) {
//       return (
//         <Link key={index} to={route.layout + "/" + route.path}>
//           <div className="relative mb-3 flex hover:cursor-pointer">
//             <li
//               className="my-[3px] flex cursor-pointer items-center px-8"
//               key={index}
//             >
//               <span
//                 className={`${
//                   activeRoute(route.path) === true
//                     ? "font-bold text-brand-500 dark:text-white"
//                     : "font-medium text-gray-600"
//                 }`}
//               >
//                 {route.icon ? route.icon : <DashIcon />}{" "}
//               </span>
//               <p
//                 className={`leading-1 ml-4 flex ${
//                   activeRoute(route.path) === true
//                     ? "font-bold text-navy-700 dark:text-white"
//                     : "font-medium text-gray-600"
//                 }`}
//               >
//                 {route.name}
//               </p>
//             </li>
//             {activeRoute(route.path) ? (
//               <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
//             ) : null}
//           </div>
//         </Link>
//       );
//     }
//   });
// };
// BRAND

import axios from 'axios';
import ManageTable from 'components/manageTable';
import { useEffect } from 'react';

const TripsTab = () => {
    const data = [
        {
            id: 1,
            passengerName: 'John Doe',
            pickup: 'Sydney Opera House, Sydney, Australia',
            dropOff: 'Sydney International Airport, Sydney, Australia',
            bookedforLater: false,
            paymentMethod: 'Credit Card',
            paidViaTTSS: true,
            travellingDateTime: '2024-03-05, 10:00',
        },
        {
            id: 2,
            passengerName: 'Jane Smith',
            pickup: 'Bondi Beach, Sydney, Australia',
            dropOff: 'Circular Quay, Sydney, Australia',
            bookedforLater: true,
            paymentMethod: 'Cash',
            paidViaTTSS: false,
            travellingDateTime: '2025-09-05, 06:30'
        },
        {
            id: 3,
            passengerName: 'Alice Johnson',
            pickup: 'Darling Harbour, Sydney, Australia',
            dropOff: 'The Rocks, Sydney, Australia',
            bookedforLater: false,
            paymentMethod: 'Cab Charge',
            paidViaTTSS: false,
            travellingDateTime: '2024-10-05, 11:27'
        }
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <ManageTable dataAPI="https://taxi2.thecaptaintaxis.com.au/passengerdetails/" columnType="trip" title="Trips" pagination={true} />
            </div>
        </div>
    );
};

export default TripsTab;

import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
// import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    drivers: [],
    passengers: [],
  });
  const navigate = useNavigate()

  useEffect(() => {
    const fetchDashboardData = async () => {
      const accessToken = localStorage.getItem('access');
      try {
        if (!accessToken) {
          localStorage.removeItem('access')
          localStorage.removeItem('refresh')
          navigate('/auth/sign-in')
        }

        const headers = {
          'Authorization': `Bearer ${accessToken}`
        }

        const [driversResponse, passengersResponse] = await Promise.all([
          fetch('https://taxi2.thecaptaintaxis.com.au/drivers/', { headers }),
          fetch('https://taxi2.thecaptaintaxis.com.au/passengerdetails/', { headers })
        ]);

        // Check for successful responses before processing
        if (!driversResponse.ok || !passengersResponse.ok) {
          throw new Error('API request failed'); // Handle errors
        }

        const driversData = await driversResponse.json();
        const passengersData = await passengersResponse.json();

        setDashboardData({
          drivers: driversData,
          passengers: passengersData,
          // ... other data properties
        });
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        // Implement error handling to display messages or retry logic
      }
    };

    fetchDashboardData();
  }, []);


  const totalEarnings = dashboardData?.passengers?.reduce((sum, passenger) => sum + parseFloat(passenger?.Price), 0) || '';

  const tableDataCheck = dashboardData?.passengers?.slice(-5).map(passenger => ({
    id: passenger.id,
    name: passenger.Passenger_Name,
    pickup: passenger.Pickup_Location,
    dropoff: passenger.Dropoff_Location,
    date: passenger.Date_Time.replace(/T/g, ' '),
  }));

  const passengers = dashboardData.passengers || [];
  const totalPassengers = passengers.length || 0;
  const paymentMethods = passengers.map(passenger => passenger.Payment_Method);
  const paymentMethodCounts = paymentMethods.reduce((acc, method) => {
    acc[method.toLowerCase()] = (acc[method.toLowerCase()] || 0) + 1;
    return acc;
  }, {});

  const pieData = [
    (Math.round((paymentMethodCounts['cash'] / totalPassengers) * 100)) || 0,
    (Math.round((paymentMethodCounts['credit card'] / totalPassengers) * 100)) || 0,
    (Math.round((paymentMethodCounts['cab charge'] / totalPassengers) * 100)) || 0,
    // (Math.round((paymentMethodCounts['ttss'] / totalPassengers) * 100)) || 0,
  ];




  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-6 w-6" />}
          title={"Total Earnings"}
          subtitle={`${Math.round(totalEarnings)}$` || 0}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Drivers"}
          subtitle={dashboardData?.drivers?.length || 0}
        />
        <Widget
          icon={<IoDocuments className="h-7 w-7" />}
          title={"Total Bookings"}
          subtitle={totalPassengers}
        />
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Check Table */}
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div>

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px]">
          {/* <DailyTraffic /> */}
          <PieChartCard pieData={pieData && pieData} />
        </div>

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default Dashboard;

import React, { useMemo } from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Link } from "react-router-dom";

const CheckTable = (props) => {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Recent Trips
        </div>

        {/* <CardMenu /> */}
      </header>
      {data?.length === 0 ? <div>No records found.</div>
        :
        <div className="mt-8 overflow-x-scroll">
          <table
            {...getTableProps()}
            className="w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                      key={index}
                    >
                      <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-navy-700 dark:active:bg-navy-600" key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "PASSENGER NAME") {
                        data = (
                          <div className="flex items-center gap-2">
                            {/* <Checkbox /> */}
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value.slice(0, 13)}{cell.value.length > 13 && '...'}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "PICKUP") {
                        data = (
                          <div className="flex items-center">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value.slice(0, 15)}{cell.value.length > 15 && '...'}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "DROPOFF") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {" "}
                            {cell.value.slice(0, 15)}{cell.value.length > 15 && '...'}
                          </p>
                        );
                      } else if (cell.column.Header === "DATE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-[16px] sm:text-[14px] "
                        >
                          <Link to={`/admin/other/trips?id=${row.original.id || 0}`}>
                            {data}
                          </Link>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      }
    </Card>
  );
};

export default CheckTable;

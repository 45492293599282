import VerticalLayout from 'components/verticalLayout'

const QRLinks = () => {
    return (
        <div>
            <div className='mt-5 mb-3'>
                <VerticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/qr_link/" type="qr_links" title="QR Links" />
            </div>
        </div>
    );
};

export default QRLinks;

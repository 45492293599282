import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";

// AUTHS PAGE
import TokenPage from "views/admin/auths/token";
import GroupsAuth from "views/admin/auths/groups";
import UsersAuth from "views/admin/auths/users";


// PRICES PAGE
import AirportPrice from "views/admin/taxiapi/prices/airport";
import FuelPrice from "views/admin/taxiapi/prices/fuel";

// MANAGEMENTS PAGE
import MaxiManagement from "views/admin/taxiapi/managements/maxi";
import SedanManagement from "views/admin/taxiapi/managements/sedan";
import SuvManagement from "views/admin/taxiapi/managements/suv";
import WCManagement from "views/admin/taxiapi/managements/wc";
import DeliveryManagement from "views/admin/taxiapi/managements/delivery";

// OTHERS PAGE
import PassengersTab from "views/admin/taxiapi/other/passengers";
import TripsTab from "views/admin/taxiapi/other/trips";
import VehiclesTab from "views/admin/taxiapi/other/vehicles";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlineWheelchairPickup,
  MdLocalAirport,
  MdSettings,
  MdLink,
  MdGeneratingTokens,
  MdAutoAwesomeMotion,
  MdGroups,
  MdOutlineSecurity,
  MdNotifications,
  MdMobileFriendly,
  MdOutlineMarkEmailRead,
  MdQrCode2,
  MdDocumentScanner,
  MdOutlineAddLink,
  MdOutlinePersonPin,
} from "react-icons/md";
import { FaBoxOpen, FaCar, FaCarSide, FaCoins, FaGasPump, FaRegAddressBook, FaRoute, FaShuttleVan, FaUsersCog } from "react-icons/fa";

import EmailNoti from "views/admin/notifications/email";
import NumberNoti from "views/admin/notifications/number";
import QRLinks from "views/admin/qrManagement/links";
import QRReporting from "views/admin/qrManagement/reporting";
import Drivers from "views/admin/drivers";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  // {
  //   name: "Auth Token",
  //   layout: "/admin",
  //   path: "auth-token",
  //   icon: <MdOutlineSecurity className="h-6 w-6" />,
  //   children: [
  //     {
  //       name: "Token",
  //       layout: "",
  //       path: "token",
  //       icon: <MdGeneratingTokens />,
  //       component: <TokenPage />,
  //     },
  //     {
  //       name: "Groups",
  //       layout: "",
  //       path: "groups",
  //       icon: <MdGroups />,
  //       component: <GroupsAuth />,
  //     },
  //     {
  //       name: "Users",
  //       layout: "",
  //       path: "user",
  //       icon: <FaUsersCog />,
  //       component: <UsersAuth />,
  //     }
  //   ],
  // },
  // {
  //   name: "Auths",
  //   layout: "/admin",
  //   path: "auths",
  //   icon: <MdOutlineSecurity />,
  //   children: [
  //     {
  //       name: "Token",
  //       layout: "",
  //       path: "token",
  //       icon: <MdGeneratingTokens />,
  //       component: <TokenPage />,
  //     },
  //     {
  //       name: "Groups",
  //       layout: "",
  //       path: "groups",
  //       icon: <MdGroups />,
  //       component: <GroupsAuth />,
  //     },
  //     {
  //       name: "Users",
  //       layout: "",
  //       path: "user",
  //       icon: <FaUsersCog />,
  //       component: <UsersAuth />,
  //     }
  //   ],
  // },
  // {
  //   name: "Taxi Api",
  //   layout: "/admin",
  //   path: "taxi",
  //   icon: <MdLink className="h-6 w-6" />,
  //   children: [
  {
    name: "Vehicles Management",
    layout: "/admin",
    path: "managements",
    icon: <MdSettings className="h-5 w-5" />,
    children: [
      {
        name: "Sedan",
        layout: "",
        path: "sedan-management",
        icon: <FaCarSide className="h-4 w-4" />,
        component: <SedanManagement />,
      },
      {
        name: "Maxi",
        layout: "",
        path: "maxi-management",
        icon: <FaShuttleVan className="h-4 w-4" />,
        component: <MaxiManagement />,
      },
      {
        name: "SUV",
        layout: "",
        path: "suv-management",
        icon: <FaCar className="h-4 w-4" />,
        component: <SuvManagement />,
      },
      {
        name: "Wheelchair",
        layout: "",
        path: "wc-management",
        icon: <MdOutlineWheelchairPickup className="h-4 w-4" />,
        component: <WCManagement />,
      },
      {
        name: "Delivery",
        layout: "",
        path: "delivery-management",
        icon: <FaBoxOpen className="h-4 w-4" />,
        component: <DeliveryManagement />,
      },
    ],
  },
  {
    name: "Pricings",
    layout: "/admin",
    path: "price",
    // component: <MainDashboard />,
    icon: <MdBarChart className="h-5 w-5" />,
    children: [
      {
        name: "Airport Fare",
        layout: "",
        path: "airport-price",
        icon: <MdLocalAirport className="h-4 w-4" />,
        component: <AirportPrice />,
      },
      {
        name: "Fuel Price/km",
        layout: "",
        path: "fuel-price",
        icon: <FaGasPump className="h-4 w-4" />,
        component: <FuelPrice />,
      },
    ],
  },
  {
    name: "Drivers",
    layout: "/admin",
    path: "drivers",
    icon: <MdOutlinePersonPin className="h-6 w-6" />,
    component: <Drivers />,
  },
  {
    name: "Bookings",
    layout: "/admin",
    path: "other",
    icon: <MdAutoAwesomeMotion className="h-5 w-5" />,
    children: [
      {
        name: "Passenger Details",
        layout: "",
        path: "passengers",
        icon: <FaRegAddressBook className="h-4 w-4" />,
        component: <PassengersTab />,
      },
      {
        name: "Trips",
        layout: "",
        path: "trips",
        icon: <FaRoute className="h-4 w-4" />,
        component: <TripsTab />,
      },
      // {
      //   name: "Vehicles",
      //   layout: "",
      //   path: "vehicles",
      //   icon: <FaCar className="h-4 w-4" />,
      //   component: <VehiclesTab />,
      // },
    ]
  },
  {
    name: "Notifications",
    layout: "/admin",
    path: "notifications",
    icon: <MdNotifications className="h-6 w-6" />,
    children: [
      {
        name: "Email Notifications",
        layout: "",
        path: "email",
        icon: <MdOutlineMarkEmailRead />,
        component: <EmailNoti />,
      },
      {
        name: "Number Notifications",
        layout: "",
        path: "number",
        icon: <MdMobileFriendly />,
        component: <NumberNoti />,
      },
    ],
  },
  {
    name: "QR Management",
    layout: "/admin",
    path: "qrManagement",
    icon: <MdQrCode2 className="h-6 w-6" />,
    children: [
      {
        name: "QR Reporting",
        layout: "",
        path: "reporting",
        icon: <MdDocumentScanner />,
        component: <QRReporting />,
      },
      {
        name: "QR Links",
        layout: "",
        path: "links",
        icon: <MdOutlineAddLink />,
        component: <QRLinks />,
      },
    ],
  },
  // ],
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;

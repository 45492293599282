import React, { useEffect, useState } from 'react';
import ManageTable from 'components/manageTable';
import axios from 'axios';

const PassengersTab = () => {
    const data = [
        {
            id: 1,
            passengerName: 'John Doe',
            contactNumber: '555-123-4567',
            emailAddress: 'dummy@gmail.com',
            dateTime: '2024-03-01, 09:30',
            // driverInstructions: 'Call upon arrival',
            // paymentMethod: 'Credit Card',
            // price: 25.00,
            // dropoffLocation: '456 Elm Street',
        },
        {
            id: 2,
            passengerName: 'Jane Smith',
            contactNumber: '555-987-6543',
            emailAddress: 'dummy2@gmail.com',
            dateTime: '2024-03-02, 11:00',
            // driverInstructions: 'Text when outside',
            // paymentMethod: 'Cash',
            // price: 30.00,
            // dropoffLocation: '321 Pine Road',
        },
        {
            id: 3,
            passengerName: 'Alice Johnson',
            contactNumber: '555-555-5555',
            emailAddress: 'dummy4@gmail.com',
            dateTime: '2024-03-03, 13:45',
            // driverInstructions: 'Use back entrance',
            // paymentMethod: 'Venmo',
            // price: 22.50,
            // dropoffLocation: '777 Maple Drive',
        }
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <ManageTable columnType="passenger" title="Passenger Details" dataAPI="https://taxi2.thecaptaintaxis.com.au/passengerdetails/" pagination={true} />
            </div>
        </div>
    );
};

export default PassengersTab;

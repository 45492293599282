import VerticalLayout from 'components/verticalLayout'

const MaxiManagement = () => {
    return (
        <div>
            <div className='mt-5 mb-3'>
            <VerticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/maxi/" type="management" title="Maxi Management" />
            </div>
        </div>
    );
};

export default MaxiManagement;

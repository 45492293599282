import React, { useEffect, useRef, useState } from 'react';
import Widget from 'components/widget/Widget';
import DataTable from 'react-data-table-component';
import { RiListSettingsFill } from 'react-icons/ri';
import { Button, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ToastProvider, useDisclosure } from '@chakra-ui/react';
import { IoMdTrash } from 'react-icons/io';
import { MdError } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { AiFillPlusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import axios from 'axios';
import DownloadImg from 'components/downloadImage';
import '../../assets/css/ManageTable.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { BiTrip } from "react-icons/bi";
import RowModal from 'components/rowModal';
import CustomLoader from 'components/customLoader';
import { customStyles } from './variables';
import columnsConfig from './variables/columnsConfig';

const ManageTable = ({ dataAPI, initialData, columnType, title, pagination }) => {
    const [data, setData] = useState();
    const [originalData, setOriginalData] = useState();
    const [changedRows, setChangedRows] = useState(null);
    const [deleteLoad, setDeleteLoad] = useState(false);
    const [saveLoad, setSaveLoad] = useState(false);
    const [render, setRender] = useState(null);
    const [populate, setPopulate] = useState(null);
    const [clearToggle, setClearToggle] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pending, setPending] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [errors, setErrors] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [formData, setFormData] = useState({});
    const [editedFields, setEditedFields] = useState([]);
    const [rowColumns, setRowColumns] = useState()
    const [renderCol, setRenderCol] = useState()
    const [isRowModalOpen, setIsRowModalOpen] = useState(false);
    const [showJump, setShowJump] = useState()
    const [reloadData, setReloadData] = useState();
    const location = useLocation();
    const navigate = useNavigate()

    const openModal = () => {
        setErrors({})
        onOpen()
    }

    const handleRowClick = (row) => {
        setErrors({})
        setSelectedRow(row);
        // setSelectedRows([{ ...row }]);
        setIsRowModalOpen(true);
    };

    const handleCloseRowModal = () => {
        setSelectedRow(null);
        setRenderCol(!renderCol)
        setShowJump(false)
        setIsRowModalOpen(false);
    };

    // FETCHING DATA FROM API OR SETTING
    useEffect(() => {
        setSearchQuery('')
        setReloadData(true)
        if (dataAPI && dataAPI.length > 0) {
            const getReponse = async () => {
                const accessToken = localStorage.getItem('access');
                try {
                    const headers = {
                        'Authorization': `Bearer ${accessToken}`
                    }

                    const response = await axios(dataAPI, { headers })

                    setData(response.data)
                    setOriginalData(response.data)
                    setPending(false)
                    setReloadData(false)
                } catch (error) {
                    console.log(error);
                    if (error.response.data.code === "token_not_valid") {
                        navigate('/auth/sign-in')
                    }
                    setPending(false)
                    setReloadData(false)
                }
            }
            getReponse()
        }
        else {
            setData(initialData)
            setPending(false)
            setReloadData(false)
        }
    }, [render]);

    // ONCE DATA IS LOADED, SAVE COLUMNS FOR ROW MODAL

    // Update filtered data when searchQuery or data changes
    useEffect(() => {
        setReloadData(false)
        const params = new URLSearchParams(location.search);
        const id = parseInt(params.get('id')) || 0;

        if (data?.length > 0 && searchQuery.length > 0) {
            const filtered = data.filter(item =>
                Object.values(item).some(value =>
                    String(value).toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            setFilteredData(filtered);
        }
        else if (params.size > 0 && id && id > 0) {
            const filterId = data?.filter(item => item.id === id) || {};
            setFilteredData(filterId)
        }
        else {
            setFilteredData(data)
        }
    }, [searchQuery, data]);

    // Handle search input change
    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    // UPDATING DATA (WORKING API)
    const handleUpdate = (newValue, id, field) => {
        setChangedRows(true);

        const updatedData = data?.map(item => {
            if (item?.id === id) {
                return { ...item, [field]: newValue };
            }
            return item;
        });

        setEditedFields(prevState => {
            // Check if the edited field already exists in the state
            const existingFieldIndex = prevState?.findIndex(item => item.id === id && item.field === field);

            if (existingFieldIndex !== -1) {
                // If the edited field exists, update its value
                const updatedDataObject = data?.find(item => item.id === id) || {};

                if (updatedDataObject.length > 0) {
                    const updatedFields = [...updatedDataObject];
                    updatedFields[existingFieldIndex].value = newValue;
                    return updatedFields;
                }
                return;
            } else {
                // If the edited field doesn't exist, add it to the state
                const updatedDataObject = data?.find(item => item.id === id);

                if (updatedDataObject) {
                    return [{ ...updatedDataObject, [field]: newValue }];
                }
                return;
            }
        });

        setFilteredData(updatedData)
        setData(updatedData);
    };
    // UPDATING DATA BUTTON
    const handleUpdateBtn = async () => {
        try {
            setSaveLoad(true)
            const accessToken = localStorage.getItem('access');

            const headers = {
                'Authorization': `Bearer ${accessToken}`
            }


            if (editedFields?.length < 1) {
                setSaveLoad(false);
                return;
            }

            const editedFieldId = editedFields[0]?.id
            delete editedFields[0]?.id

            const originalField = originalData?.find(item => item.id === editedFieldId);
            let updatedField = {};

            if (columnType === 'driver') {
                Object.keys(editedFields[0])?.forEach(field => {
                    if (editedFields[0][field] !== originalField[field]) {
                        updatedField[field] = editedFields[0][field];
                    }
                });
            } else {
                updatedField = editedFields[0];
            }

            const updatingData = await axios.put(`${dataAPI}${editedFieldId}/`, updatedField, { headers })

            if (updatingData?.status === 200) {
                toast('Changes Saved Successfully.')
                handleCloseRowModal()
                setRender(!render)
            } else {
                toast('Something went wrong!')
            }
            setChangedRows(false)
            setSaveLoad(false);
        } catch (error) {
            setSaveLoad(false)
            console.log(error)

            if (error?.response?.data && typeof (error.response.data) === 'object') {
                setErrors(error.response.data)
            }
            toast("Error Saving Changes.")
        }
    }


    // ADDING & UPDATING DATA FROM MODAL TO BACKEND
    const handleAddData = (field, id, column) => {
        if (field.target.type === 'file') {
            const file = field.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(',')[1];

                setFormData(formData => ({
                    ...formData,
                    [column]: base64String
                }));
            };

            reader.readAsDataURL(file);
            // If the input is a file input
            // const file = field.target.files[0];
            // setFormData(formData => ({
            //     ...formData,
            //     [column.accessor]: file
            // }));
        } else {
            // For text inputs
            const fieldValue = field.target.value;
            setFormData(formData => ({
                ...formData,
                [column]: fieldValue
            }));
        }
    }
    const handleAddBtn = async () => {
        setSaveLoad(true)
        try {
            setErrors({});

            const newErrors = {};

            const fieldsToSkip = ['id', 'trips', 'Aus Citi Medi', 'Vevo Papers', 'Passport ID', 'English Language']

            if (Object.keys(formData).length > 0) {
                for (let key in formData) {
                    const keyFormat = key.replace(/_/g, ' ')
                    if (fieldsToSkip.includes(keyFormat)) continue;
                    if (!formData[key]) {
                        newErrors[keyFormat] = "Field can't be empty.";
                    } else if (newErrors[keyFormat]) {
                        delete newErrors[keyFormat]
                    }
                }
            } else {
                newErrors['general'] = 'All fields are required.'
            }

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                setSaveLoad(false)
                return;
            }

            const formDataToSend = new FormData();

            // Append form data to FormData object
            for (let key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            // Send multipart form data to backend
            const accessToken = localStorage.getItem('access');

            const headers = {
                'Authorization': `Bearer ${accessToken}`
            }

            const addData = await axios.post(dataAPI, formDataToSend, { headers });

            if (addData.status === 200 || addData.status === 201) {
                setData([...data, formData])
                setPopulate(!populate)
                setRender(!render)
                setSaveLoad(false)
                onClose()
                toast('Data Added Successfully.')
            } else {
                setSaveLoad(false)
                toast('Something went wrong!')
            }
        } catch (error) {
            setSaveLoad(false)
            console.log(error)

            if (error?.response?.data && typeof (error.response.data) === 'object') {
                setErrors(error.response.data)
            }
            toast('Error occured while adding data.')
        }
    }

    // ALL TABLE COLUMNS

    const handlePassengerTrips = (row) => {
        setRowColumns(columnsConfig[`tripColumns`])
        setShowJump(true)
        handleRowClick(row)
    }

    // useEffect(() => {
    //     setReloadData(false)
    //     columnsConfig && handlePassengerTrips &&
    //         setRowColumns(columnsConfig[`${columnType}Columns`])(title, handlePassengerTrips)
    // }, [renderCol]);


    const columns = columnsConfig[`${columnType}Columns`](title, handlePassengerTrips)

    // POPULATING MODAL WITH COLUMNS INPUTS 
    useEffect(() => {
        const handleInitializeFormData = () => {
            const initialFormData = {};

            // Populate formData with column names and empty values
            columnType === 'passenger' || columnType === 'trip' ?
                [...columnsConfig.passengerColumns(title, handlePassengerTrips), ...columnsConfig.tripColumns(title, handlePassengerTrips)].forEach(column => {
                    column.accessor?.toLowerCase() !== 'id' && (initialFormData[column.accessor] = '');
                })
                :
                columns && columns?.forEach(column => {
                    column.accessor?.toLowerCase() !== 'id' && (initialFormData[column.accessor] = '');
                });

            setFormData(initialFormData);
        }
        handleInitializeFormData()
    }, [populate])

    // HANDLING ROW SELECTION
    const handleSelectBox = (rows) => {
        setSelectedRows(rows.selectedRows);
    };

    // DELETING DATA (WORKING API)
    const handleDelete = async () => {
        setDeleteLoad(true)
        try {
            const accessToken = localStorage.getItem('access');

            const headers = {
                'Authorization': `Bearer ${accessToken}`
            }

            const deleteData = data?.filter(row => selectedRows?.includes(row) || row.id === selectedRow?.id);
            const newFilteredData = filteredData?.length > 0 && filteredData.filter(row => !selectedRows.includes(row))

            const deleteRow = await axios.delete(`${dataAPI}${deleteData[0].id}/`, {
                headers: headers,
                data: deleteData // Sending data as URL parameters
            });

            setSelectedRows([]);
            setClearToggle(!clearToggle)
            setDeleteLoad(false)
            handleCloseRowModal()
            onClose()
            setRender(!render)
            toast('Data Deleted Successfully.')
            setSearchQuery('');
        } catch (error) {
            console.log(error)
            toast.error('Error occurred in Deleting Data.')
            setDeleteLoad(false)
        }
    };

    const handleReloadData = () => {
        setReloadData(true)
        const params = new URLSearchParams(location?.search);
        const id = parseInt(params?.get('id')) || 0;

        if (id && params.size > 0 && id > 0) {
            params.delete('id');
            navigate(window.location.pathname)
            setRender(!render)
        } else {
            setRender(!render)
        }
    }

    return (
        <div>
            <div className='mt-5 mb-3'>
                <Widget
                    icon={<RiListSettingsFill className="h-7 w-7" />}
                    title={"Manage Data"}
                    subtitle={title}
                />
            </div>

            <RowModal
                isOpen={isRowModalOpen}
                onClose={handleCloseRowModal}
                selectedRow={selectedRow}
                columns={rowColumns || columns}
                onUpdate={handleUpdate}
                onSave={handleUpdateBtn}
                onDelete={handleDelete}
                saveLoad={saveLoad}
                errors={errors}
                showJump={showJump}
                columnType={columnType}
            />

            {columnType !== 'qr' &&
                <>
                    {/* MODAL RENDERING */}
                    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick isCentered scrollBehavior='inside' size="full">
                        <ModalOverlay
                            bg='none'
                            backdropFilter='auto'
                            backdropInvert='80%'
                            backdropBlur='2px'
                        />
                        <ModalContent>
                            <ModalHeader>
                                <div className='sm:text-[28px] font-bold'>Add Data</div>
                                <ModalCloseButton className='mt-3 mr-2' fontSize={14} />
                            </ModalHeader>

                            <ModalBody>
                                {/* INPUTS RENDERING */}
                                {
                                    columnType === 'passenger' || columnType === 'trip' ?
                                        [...columnsConfig.passengerColumns(title, handlePassengerTrips), ...columnsConfig.tripColumns(title, handlePassengerTrips)]?.map(column => (
                                            column?.name !== "ID" && column?.name !== "Trips" ? (
                                                <div className='flex flex-col sm:flex-row gap-2 flex-wrap mb-4 sm:items-center' key={column?.name}>
                                                    <label className='font-bold text-navy-700'>{column?.name}:</label>
                                                    {column?.fileInput ? (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            id={column?.id}
                                                            name={column?.accessor}
                                                            onChange={(item) => handleAddData(item, column?.id, column?.accessor)}
                                                        />
                                                    ) : (
                                                        <div className='text-navy-900  cell-inputs-edit flex-grow'>
                                                            {column?.cell({}, handleAddData)}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null
                                        ))
                                        :
                                        columns?.map(column => (
                                            column?.name !== "ID" && column?.name !== "Trips" ? (
                                                <div className='flex flex-col sm:flex-row gap-2 flex-wrap mb-4 sm:items-center' key={column?.name}>
                                                    <label className='font-bold text-navy-700'>{column?.name}:</label>
                                                    {column.fileInput ? (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            id={column?.id}
                                                            name={column?.accessor}
                                                            onChange={(item) => handleAddData(item, column?.id, column?.accessor)}
                                                        />
                                                    ) : (
                                                        <div className='text-navy-900  cell-inputs-edit flex-grow'>
                                                            {column?.cell({}, handleAddData)}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null
                                        ))
                                }

                                {/* ERRORS RENDERING */}
                                {Object.keys(errors)?.length > 0 && (
                                    <div className=''>
                                        {Object.entries(errors)?.map(([field, value], index) => {
                                            return (
                                                <div className='flex gap-2 flex-wrap items-center -mt-2 mb-2' key={index}>
                                                    <MdError color='red' />
                                                    <p className='font-semibold text-brand-700 m-0'>{field?.charAt(0).toUpperCase() + field?.slice(1)}: </p>
                                                    <p className='font-semibold text-red-500 m-0'>{value}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    className={`linear flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 mr-3`}
                                    onClick={handleAddBtn}
                                    isLoading={saveLoad}
                                    loadingText="Saving"
                                    spinnerPlacement='end'
                                >Add</Button>

                                <Button
                                    className={`linear flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`} onClick={onClose}
                                >Close</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* ADD ICON RENDER */}
                    <AiFillPlusCircle className='fixed bottom-8 md:bottom-6 z-50 right-6 cursor-pointer rounded-full shadow shadow-gray-800 text-brand-600 dark:text-brand-300 hover:text-brand-700 active:text-brand-800' onClick={openModal} size="35" />
                </>
            }


            {/* DATA TABLE RENDERING */}
            <DataTable
                title={
                    <div className='flex flex-col justify-center flex-wrap gap-3 w-1/2 mt-2'>
                        {title}

                        <div className='flex gap-1 sm:gap-3 flex-wrap items-center justify-start mr-2'>
                            {title.toLowerCase() === "qr reporting" ?
                                <>
                                    <div className="flex items-center justify-center">
                                        <div className="h-2 w-2 rounded-full bg-blue-200" />
                                        <p className="ml-1 text-sm font-normal text-blue-900">Web</p>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <div className="h-2 w-2 rounded-full bg-yellow-200" />
                                        <p className="ml-1 text-sm font-normal  text-yellow-900">Android</p>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <div className="h-2 w-2 rounded-full bg-green-200" />
                                        <p className="ml-1 text-sm font-normal text-green-900">iOS</p>
                                    </div>
                                </>
                                // TRIPS RENDER
                                : title.toLowerCase() === "trips" ?
                                    <>
                                        <div className="flex items-center justify-center">
                                            <div className="h-2 w-2 rounded-full bg-[#FFD700]" />
                                            <p className="ml-1 text-sm font-normal text-gray-600">Cash</p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <div className="h-2 w-2 rounded-full bg-[#00FF00]" />
                                            <p className="ml-1 text-sm font-normal text-gray-600">Credit Card</p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <div className="h-2 w-2 rounded-full bg-[#FFA500]" />
                                            <p className="ml-1 text-sm font-normal text-gray-600">Cab Charge</p>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <div className="h-2 w-2 rounded-full bg-[#C2DFFF]" />
                                            <p className="ml-1 text-sm font-normal text-gray-600">TTSS</p>
                                        </div>
                                    </>
                                    : ''
                            }
                        </div>
                        <Button
                            isLoading={reloadData}
                            className='linear w-fit flex items-center justify-center rounded-xl bg-brand-500 px-3 py-2 text-xs font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
                            colorScheme='blue'
                            onClick={handleReloadData}
                        >Reload</Button>
                    </div>
                }
                pagination={pagination}
                data={filteredData?.length > 0 ? filteredData : data}
                columns={columns?.slice(0, 3)}
                className='data-table relative'
                customStyles={customStyles}
                progressPending={pending}
                progressComponent={<CustomLoader />}
                striped
                highlightOnHover
                pointerOnHover
                selectableRows
                clearSelectedRows={clearToggle}
                onSelectedRowsChange={handleSelectBox}
                onRowClicked={handleRowClick}
                subHeader
                subHeaderComponent={
                    <div className="relative mt-[3px] flex h-[61px] w-full flex-grow items-center justify-end gap-3 rounded-full  px-4 py-2 md:w-fit md:flex-grow-0 md:gap-3 xl:w-fit xl:gap-3">
                        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 xl:w-[225px]">
                            <p className="pl-3 pr-2 text-xl">
                                <FiSearch className="h-4 w-4 text-gray-400" />
                            </p>
                            <input
                                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 sm:w-fit"
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                }
            />

            {/* BUTTONS RENDERING */}
            <div className='flex gap-3 flex-wrap w-full items-center justify-end'>
                <Button
                    className={`linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${selectedRows?.length > 0 ? '' : 'pointer-events-none opacity-60'}`}
                    onClick={handleDelete}
                    isLoading={deleteLoad}
                    loadingText="Delete"
                    spinnerPlacement='end'
                    rightIcon={<IoMdTrash size='17' />}
                >
                    Delete
                </Button>
            </div>
        </div>
    );
};

export default ManageTable;
import React from 'react';
import ManageTable from 'components/manageTable';
import VeticalLayout from 'components/verticalLayout';

const AirportPrice = () => {
    const data = [
        {
            id: 1,
            price: 20
        }
    ];

    return (
        <div>
            <div className='mt-5 mb-3'>
                <VeticalLayout dataAPI="https://taxi2.thecaptaintaxis.com.au/airport/" type="price" title="Airport Fare" />
            </div>
        </div>
    );
};

export default AirportPrice;
